import { useContext, useEffect, useRef, useState } from "react";
import "./Header.css";
import { ConfigContext } from "../../assets/contexts/configContext";
import { Link, NavLink } from "react-router-dom";
import {
  CART_MAIN_LINK,
  CATALOG_MAIN_LINK,
  HEADER_CUSTOM_LINKS,
} from "../../assets/utils/constants";
import { ActiveLinkIcon, CustomCartIcon, CloseIcon, MenuIcon, SearchIcon } from "../../assets/icons/icons";
import mainApi from "../../assets/api/MainApi";
import CatalogDrop from "./CatalogDrop/CatalogDrop";
import MenuPopup from "./MenuPopup/MenuPopup";
import { CartContext } from "../../assets/contexts/cartContext";
import useScrollDirection from "../../assets/hooks/useScrollDirection";

import { getURLRelativePath, isExternalURL } from "../../assets/utils/utils";
import Search from "../Search/Search";
import useWindowSize from "../../assets/hooks/useWindowSize";

const desiredOrder = ['Римские шторы', 'Ткани для штор', 'Комплекты штор'];
const orderMap = desiredOrder.reduce((map, name, index) => {
  map[name] = index;
  return map;
}, {});


function Header() {
  const scrollDirection = useScrollDirection();
  const { width } = useWindowSize();
  const [searchOpen, setSearchOpen] = useState(false)

  const config = useContext(ConfigContext);
  const cart_context = useContext(CartContext)
  const {
    cart,
    handleAddToCart,
    handleRemoveFromCart,
    setCart,
  } = cart_context
  const { initialConstants } = config
  const { FIRST_LEVEL_CATEGORIES, HEADER_LINKS } = initialConstants

  const catalogBtnRef = useRef(null);
  const linksRef = useRef(null);

  const [isCatalogOpen, setCatalogOpen] = useState(false);
  // const [categories, setCategories] = useState(FIRST_LEVEL_CATEGORIES && FIRST_LEVEL_CATEGORIES.length > 0 && Array.isArray(FIRST_LEVEL_CATEGORIES) ? FIRST_LEVEL_CATEGORIES : []);
  const [topCategories, setTopCategories] = useState(undefined);

  // useEffect(() => {
  //   mainApi
  //     .getCategories({
  //       limit: 25,
  //       shop_id: "65ae789bcb5f6a9f66e457aa",
  //     })
  //     .then((res) => {
  //       console.log(res.data);
  //       setCategories(res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  const categoriesRef = useRef(null);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [scrollWindowPosition, setScrollWindowPosition] = useState(0);
  const [popupScrollPosition, setPopupScrollPosition] = useState(0);
  const [visibleCategories, setVisibleCategories] = useState([]);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollWindowPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
      document.body.style.top = `-${scrollWindowPosition}px`;
      setPopupScrollPosition(scrollWindowPosition);
      setTimeout(() => {
        document.body.style.position = 'fixed';
        document.body.style.width = '100%';
        document.body.style.height = '100%';
      }, 10);

      console.log(window.pageYOffset);
    } else {
      document.body.style.overflow = 'unset';
      document.body.style.position = 'unset';
      document.body.style.width = 'unset';
      document.body.style.height = 'unset';
      document.body.style.top = `unset`;
      console.log(popupScrollPosition);
      window.scrollTo(0, popupScrollPosition);
      setScrollWindowPosition(popupScrollPosition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMenuOpen]);

  useEffect(() => {
    const sortedCategories = FIRST_LEVEL_CATEGORIES.sort((a, b) => {
      const indexA =
        orderMap[a.name] !== undefined
          ? orderMap[a.name]
          : FIRST_LEVEL_CATEGORIES.length;
      const indexB =
        orderMap[b.name] !== undefined
          ? orderMap[b.name]
          : FIRST_LEVEL_CATEGORIES.length;
      return indexA - indexB;
    });

    const updateVisibleCategories = () => {
      if (categoriesRef.current) {
        const maxAllowedWidth = categoriesRef.current.offsetWidth;
        if (maxAllowedWidth <= 0) return;
        let totalWidth = 0;
        const categoriesToShow = [];

        sortedCategories.forEach((category, index) => {
          const categoryElement = document.createElement('div');
          categoryElement.className = 'category';
          categoryElement.style.visibility = 'hidden';
          categoryElement.style.position = 'absolute';
          categoryElement.innerText = category.name;
          document.body.appendChild(categoryElement);
          const categoryWidth = categoryElement.offsetWidth + 28 * 2;
          document.body.removeChild(categoryElement);

          if (totalWidth + categoryWidth <= maxAllowedWidth) {
            categoriesToShow.push(category);
            totalWidth += categoryWidth;
          }
        });

        setVisibleCategories(categoriesToShow);
      }
    };

    updateVisibleCategories();
    window.addEventListener('resize', updateVisibleCategories);

    return () => window.removeEventListener('resize', updateVisibleCategories);
  }, [FIRST_LEVEL_CATEGORIES]);

  return (
    <header
      className={`header  ${scrollDirection === 'down' ? 'header_hide' : 'header_show'
        }`}
    >
      <MenuPopup
        isOpened={isMenuOpen}
        setOpened={setMenuOpen}
        categories={FIRST_LEVEL_CATEGORIES}
      />
      <div className="header__content">
        <button
          className="header__menu"
          type="button"
          onClick={() => setMenuOpen(true)}
        >
          <MenuIcon
            mainClassName={'header__menu-icon'}
            fillClassName={'header__menu-icon-fill'}
          />
        </button>

        <Link className="header__logo" to={'/'}>
          <img
            className="header__logo-img"
            src={config.platfromTokens.logo.url}
            alt=""
          />
        </Link>

        <div className="header__catalog-with-categories">
          <div className="header__search">
            <div className="search_pc">
              <button className="header__input-icon" onClick={() => setSearchOpen(true)} type="button">
                <SearchIcon mainClassName={'header__input-icon'} fillClassName={'header__input-icon-fill'} />
              </button>
              <Search searchOpen={searchOpen} setSearchOpen={setSearchOpen} maxWidth={linksRef && linksRef.current ? linksRef.current.offsetWidth + 28 + 20 : '700px'} />
            </div>
          </div>

          <div className="header__categories-box">
            <NavLink
              className={({ isActive }) => {
                return `header__category ${isActive ? 'header__category_active' : ''
                  }`;
              }}
              to={`/`}
            >
              Главная
              <ActiveLinkIcon
                mainClassName="header__active-icon"
                fillClassName="header__active-icon-fill"
              />
            </NavLink>
            {FIRST_LEVEL_CATEGORIES && FIRST_LEVEL_CATEGORIES.length > 0 ? (
              <div className="header__categories" ref={categoriesRef}>
                {visibleCategories.map((category) => (
                  <NavLink
                    key={category._id}
                    className={({ isActive }) => {
                      return `header__category ${isActive ? 'header__category_active' : ''
                        }`;
                    }}
                    to={`/${CATALOG_MAIN_LINK}/${category.translit_name}`}
                  >
                    {category.name}
                    <ActiveLinkIcon
                      mainClassName="header__active-icon"
                      fillClassName="header__active-icon-fill"
                    />
                  </NavLink>
                ))}
              </div>
            ) : null}
            {HEADER_CUSTOM_LINKS.map((item) => (
              <NavLink
                key={item.name}
                className={({ isActive }) => {
                  return `header__category ${isActive ? 'header__category_active' : ''
                    }`;
                }}
                to={`/${item.link}`}
              >
                {item.name}
                <ActiveLinkIcon
                  mainClassName="header__active-icon"
                  fillClassName="header__active-icon-fill"
                />
              </NavLink>
            ))}
          </div>
        </div>
        <div className="header__cart-and-menu">
          {/* <Link className="header__link-btn" type="button">
            <LikeIcon
              mainClassName="header__btn-icon"
              strokeClassName="header__btn-icon-stroke"
            />
          </Link>
          <Link className="header__link-btn" type="button">
            <ProfileIcon
              mainClassName="header__btn-icon"
              strokeClassName="header__btn-icon-stroke"
            />
          </Link> */}
          <button className="header__search-icon-mobile" onClick={() => setSearchOpen(true)} type="button">
            <SearchIcon mainClassName={'header__input-icon'} fillClassName={'header__input-icon-fill'} />
          </button>
          <Link className="header__link-btn" to={`/${CART_MAIN_LINK}`}>
            {cart && cart.length > 0 ? (
              <p className="header__cart-count">{cart.length}</p>
            ) : null}
            <CustomCartIcon
              mainClassName="header__btn-icon"
              strokeClassName="header__btn-icon-stroke"
            />
          </Link>
        </div>
        <div className="search_mobile">
            <Search searchOpen={searchOpen} setSearchOpen={setSearchOpen} maxWidth={`100%`} />
          </div>
      </div>
    </header>
  );
}

export default Header;