import { BOT_TOKEN, CURRENCY_MAP } from "./constants";

export const sendMetriс = ({ type, value, counters }) => {

    // const COUNTERS_EXEMPLE = [
    //     {
    //         id: '94194405',
    //     },
    // ]
    if (!counters || counters.length === 0) return
    for (let index = 0; index < counters.length; index++) {
        const counter = counters[index];
        window.ym(counter.id, type, value)
    }
}

export function getRandomId() {
    const ObjectId = (
        m = Math,
        d = Date,
        h = 16,
        s = (s) => m.floor(s).toString(h)
    ) => s(d.now() / 1000) + " ".repeat(h).replace(/./g, () => s(m.random() * h));
    return ObjectId();
}


export const getFinalPrice = (product, promo) => {
    const price_with_discount = product ? product.discount ? (Number(product.price) - Number(product.price) / 100 * Number(product.discount)) : Number(product.price) : 0
    if (promo && promo.isValid) {
        return price_with_discount - (price_with_discount / 100 * Number(promo.discount))
    } else {
        return price_with_discount
    }
}


export const buildCategoryPath = (categories, currentIndex, isTranslitName = true) => {
    return categories.slice(0, currentIndex + 1).map(cat => isTranslitName ? cat.translit_name : cat).join('/');
};

export function parsePrice(string, currency) {
    return Number(string) ? `${Number(string).toLocaleString('us')} ${CURRENCY_MAP[currency.toUpperCase()] ? CURRENCY_MAP[currency.toUpperCase()] : ''}` : `0 ${CURRENCY_MAP[currency.toUpperCase()]}`
}


export function parseParameterValue(parameter) {
    switch (parameter.type) {
        case 'boolean':
            return parameter.value ? 'Да' : 'Нет'

        case 'number':
            return Number(parameter.value).toLocaleString()


        default:
            return parameter.value
    }
}

export function getCorrectWordForm(count) {
    return 'шт.'
    // let lastDigit = count % 10;
    // if (count >= 11 && count <= 19) {
    //     return 'товаров';
    // }
    // switch (lastDigit) {
    //     case 1:
    //         return 'товар';
    //     case 2:
    //     case 3:
    //     case 4:
    //         return 'товара';
    //     default:
    //         return 'товаров';
    // }
}

export function compareOptions(array1, array2) {
    // Initialize objects to store matches, unique elements from each array, and differences
    const result = {
        matches: [],
        uniqueInArray1: [],
        uniqueInArray2: [],
        differences: [] // Objects with the same name but different values
    };

    // Convert array2 into a Map for efficient lookup by name
    const mapArray2 = new Map(array2.map(item => [item.name, item]));

    // Compare elements of array1 to array2
    array1.forEach(item1 => {
        const item2 = mapArray2.get(item1.name);
        if (item2) {
            if (item1.value === item2.value) {
                result.matches.push(item1);
            } else {
                result.differences.push({ name: item1.name, value1: item1.value, value2: item2.value });
            }
            mapArray2.delete(item1.name); // Remove matched item to find unique items in array2 later
        } else {
            result.uniqueInArray1.push(item1);
        }
    });

    // Remaining items in mapArray2 are unique to array2
    result.uniqueInArray2 = Array.from(mapArray2.values());

    return result;
}

export function setCookieWithShop(cookie_name, shop_id, value, daysToLive) {
    const name = cookie_name + shop_id;
    let expires = "";
    if (daysToLive) {
        const date = new Date();
        date.setTime(date.getTime() + (daysToLive * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export function getCookieWithShop(name, shop_id) {
    const nameEQ = name + shop_id + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}



export function getMinPrice(valuesArray) {
    let minPrice = Infinity;  // Инициализируем переменную для хранения минимального значения ценой бесконечности
    for (let i = 0; i < valuesArray.length; i++) {
        if (valuesArray[i].price < minPrice) {
            minPrice = valuesArray[i].price;
        }
    }
    return minPrice;
}

export const isExternalURL = (url) => {
    const currentDomain = window.location.origin; // Get current domain
    return !url.startsWith(currentDomain) && /^(http|https):/.test(url); // Check if URL is external
};

export const getURLRelativePath = (url) => {
    const basename = '/' + (window.initialConstants.ROOT_PATH === '{{ROOT_PATH}}' ? '' : window.initialConstants.ROOT_PATH);
    const currentDomain = window.location.origin;
    let relativePath = url.startsWith(currentDomain) ? url.replace(currentDomain, '') : url;

    // If there's a basename, remove it from the relative path
    if (basename && relativePath.startsWith(basename)) {
        relativePath = relativePath.replace(basename, '');
    }

    return relativePath;
};

// export const getPrice = (item) => {

//     const name = localStorage.getItem('city') ? localStorage.getItem('city') : 'Тобольск'
//     let cityMap = {
//         "Новый Уренгой": "63777e52c505252a8fc59c09",
//         "Надым": "63777e62c505252a8fc59c0a",
//         "Тобольск": "63777e74c505252a8fc59c0b",
//     }
//     let id = cityMap[name] ? cityMap[name] : "63777e74c505252a8fc59c0b"
//     let value = item.firstc_data.price[id]
//     return Number(value)
// }

export function formatStringOrNull(string) {
    return string ? string : null
}

export function formatNewAddress(delivery_data) {
    const { store_address, additional_address_data } = delivery_data

    const entrance = additional_address_data && additional_address_data.entrance && additional_address_data.entrance !== '-' ? `подъезд ${additional_address_data.entrance}` : '';
    const entry_code = additional_address_data && additional_address_data.entry_code && additional_address_data.entry_code !== '-' ? `домофон ${additional_address_data.entry_code}` : '';
    const floor = additional_address_data && additional_address_data.floor && additional_address_data.floor !== '-' ? `этаж ${additional_address_data.floor}` : '';
    const apartment = additional_address_data && additional_address_data.apartment && additional_address_data.apartment !== '-' ? `кв. ${additional_address_data.apartment}` : '';
    const comment = additional_address_data && additional_address_data.comment && additional_address_data.comment !== '-' ? `комментарий ${additional_address_data.comment}` : '';

    // Combine parts with appropriate commas and filter out empty strings
    const formattedAddress = [store_address, apartment, entry_code, entrance, floor, comment].filter(part => part !== '').join(', ');
    return formattedAddress;
}

export const sendTextTgBot = async (chatId, sendText, setPreloaders) => {
  const text = sendText;
  try {
    if (setPreloaders) {
      setPreloaders(true);
    }
    const response = await fetch(
      `https://api.telegram.org/bot${BOT_TOKEN}/sendMessage?chat_id=${chatId}&text=${encodeURIComponent(
        text
      )}`
    );
    const data = await response.json();
    console.log(data);
    // Handle success (e.g., clear form, show success message)
  } catch (error) {
    console.error('Error:', error);
    // Handle error
  } finally {
    if (setPreloaders) {
      setPreloaders(false);
    }
  }
};


